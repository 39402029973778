<template>
  <transition :name="transitionName">
    <slot>No slot content...</slot>
  </transition>
</template>

<script>
  export default {
    name: 'swipe-transition',
    props: {
      direction: {
        validator(value) {
          return value === 'left' || value === 'right' || value === 'up' || value === 'down';
        },
        default: 'left',
      },
    },
    computed: {
      transitionName() {
        return `swipe${this.direction}`;
      },
    },

  };
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
  .swipedown-enter-active, .swipedown-leave-active,
  .swipeup-enter-active, .swipeup-leave-active,
  .swipeleft-enter-active, .swipeleft-leave-active,
  .swiperight-enter-active, .swiperight-leave-active {
    transition: all .2s
  }

  .swipedown-enter, .swipedown-leave-to {
    transform: translateY(-100vh);
  }

  .swipeup-enter, .swipeup-leave-to {
    transform: translateY(100vh);
  }

  .swipeleft-enter, .swipeleft-leave-to {
    transform: translateX(100vw);
  }

  .swiperight-enter, .swiperight-leave-to {
    transform: translateX(-100vw);
  }

</style>
