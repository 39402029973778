<template>
  <div id="popupList">
    <swipe-transition v-for="(popup, index) in popups" :key="'transition'+index"
                      :direction="popup.direction">
      <popup :id="'popup'+index" :key="'popup'+index" :content="popup"
             :popup-index="index+1"></popup>
    </swipe-transition>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Popup from './Popup';
  import SwipeTransition from '../Transitions/SwipeTransition';

  export default {
    name: 'popups',
    components: {
      'swipe-transition': SwipeTransition,
      popup: Popup,
    },
    data() {
      return {
        previousCount: 0,
      };
    },
    computed: mapState(['popups', 'config']),
  };
</script>
