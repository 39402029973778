+´´<template>
  <div class='DynPopUp ThemeBackgroundColor' :class="{ showTakePicture : showTakePicture }">
    <dl class='subGrid'>
      <dt class='topbar'>
        <span class='title'>{{ popUpTitle }}
          <span v-if="showSuffix">&nbsp;{{ topBarSuffix }}</span>
        </span>
        <a @click.once="closePopup">
          <icon name='fa-close ThemeFavIcon'/>
        </a>
      </dt>
      <dd id="popUpArea">
        <loading-sign v-if="!hasLoaded"/>
        <component :is="rawComponent" v-show="hasLoaded" v-bind="content.params"
                   :title="content.title"
                   @didLoad="onComponentLoaded"
                   @close="closePopup"/>
      </dd>
    </dl>
  </div>
</template>

<script>
  import { toRaw } from 'vue';
  import { mapState } from 'vuex';
  import translate from '../Mixins/Translate';
  import LoadingSign from '../LoadingSign';

  export default {
    name: 'popup',
    props: {
      // The index number of the popup. Is used for determining z-index.
      popupIndex: {
        type: Number,
        required: true,
      },
      // An object specifying info about the popup to be shown:
      // Should conform to the PopupOptions object, where all properties are documented.
      // NOTE that all properties passed this way must be specified
      // in the "props" section of the child component.
      content: {
        type: Object,
        required: true,
      },
    },
    mixins: [translate],
    components: {
      'loading-sign': LoadingSign,
    },
    data() {
      let hasLoaded = false;
      if (this.content.waitForLoad === false) hasLoaded = true;
      return {
        hasLoaded,
        isClosing: false,
      };
    },
    computed: {
      /** The component data provided is probably reactive.
       * But it shouldn't be. Component definitions that are
       * reactive incur a performance overhead and a vue warning.
       * So we return the raw, un-proxied object here and use that. */
      rawComponent() {
        return toRaw(this.content.component);
      },
      popUpTitle() {
        return this.content.title;
      },
      showSuffix() {
        return this.content.showSuffix || false;
      },
      showTakePicture() {
        return this.content.showTakePicture || false;
      },
      ...mapState(['config', 'topBarSuffix']),
    },
    methods: {
      async closePopup() {
        this.isClosing = true;
        setTimeout(() => {
          this.$store.commit('popPopup');
        }, 400);
      },
      onComponentLoaded() {
        this.hasLoaded = true;
      },
    },
  };
</script>

<style scoped lang="scss">
  .DynPopUp.showTakePicture {
    height: calc(100% - 50px) !important;
  }
</style>
