<template>
  <div>
    <ul id="grid">
      <top-bar ref="topBar"/>
      <app-menu ref="appMenu"/>
      <li id="contentMain">
        <div id="contentArea">
          <router-view/>
        </div>
      </li>

      <li id="contentBottom" class="ThemeBackgroundColor">
        <ul class="bottom">
          <li>{{x('take')}}</li>
          <li class="upload user"><a @click="showTakePicture"><img
            src="static/assets/gfx/shutter.png" alt="shutter"></a></li>
          <li>{{x('picture')}}</li>
        </ul>
      </li>
    </ul>

    <popups/>
    <zoom-container/>
    <div id="uploadContainer"></div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState, mapGetters } from 'vuex';
  import SurveyChooser from '@/components/SurveyChooser';
  import Events from '@/enums/event-names';
  import translate from './Mixins/Translate';
  import TopBar from './TopBar';
  import AppMenu from './AppMenu';
  import Popups from './Popup/Popups';

  const TakePicture = defineAsyncComponent(() => import('./EditVisit/EditVisit'));
  const Survey = defineAsyncComponent(() => import('./Survey/Survey'));
  const ZoomContainer = defineAsyncComponent(() => import('./ZoomContainer'));
  const SurveyError = defineAsyncComponent(() => import('./Survey/SurveyError'));
  const NoTakePicture = defineAsyncComponent(() => import('./NoTakePicture'));

  export default {
    name: 'app',
    components: {
      'top-bar': TopBar,
      'app-menu': AppMenu,
      popups: Popups,
      'zoom-container': ZoomContainer,
    },
    mixins: [translate],
    computed: {
      ...mapState(['user']),
      ...mapState('VisitPlanStore', ['visitPlans']),
      ...mapGetters('SurveyStore', ['surveyCount']),
      ...mapGetters('FeatureToggleStore', ['areSurveysEnabled', 'areVisitPlansEnabled']),
    },
    async mounted() {
      this.registerEventHandlers();
      window.addEventListener('resize', (e) => {
        const size = {
          width: e.target.innerWidth,
          height: e.target.innerHeight,
        };
        this.$store.commit('WindowStore/setWindowSize', size);
      });
    },
    beforeUnmount() {
      this.unregisterEventHandlers();
    },
    methods: {
      registerEventHandlers() {
        this.$bus.on(Events.takePicture, this.showTakePicture);
      },
      unregisterEventHandlers() {
        this.$bus.off(Events.takePicture, this.showTakePicture);
      },
      showTakePicture() {
        if (this.user.userRole === 'external'
          && this.areVisitPlansEnabled
          && this.visitPlans.length === 0) {
          this.$store.commit('pushPopup', {
            component: NoTakePicture,
            title: this.x('takePicture'),
            direction: 'up',
          });
          return;
        }

        if (!this.areSurveysEnabled) {
          this.$store.commit('pushPopup', {
            component: TakePicture,
            title: this.x('takePicture'),
            direction: 'up',
          });
          return;
        }

        if (this.surveyCount > 1) {
          this.$store.commit('pushPopup', {
            component: SurveyChooser,
            title: this.x('choose'),
            direction: 'up',
            hideWhenShowingNext: true,
          });
          return;
        }

        if (this.surveyCount === 1) {
          this.$store.commit('pushPopup', {
            component: Survey,
            title: this.x('fillOutSurvey'),
            direction: 'up',
            showSuffix: true,
          });
          return;
        }

        // Error condition: Survey is enabled via reactions, but survey configuration is
        // not correct.
        this.$store.commit('pushPopup', {
          component: SurveyError,
          title: this.x('fillOutSurvey'),
          direction: 'up',
        });
      },
    },
  };
</script>
