<template>
  <div>
    <i class='fa fa-cog fa-spin'></i><br>{{ x('retrievingData') }}
  </div>
</template>

<script>
  import translate from './Mixins/Translate';

  export default {
    name: 'loading-sign',
    mixins: [translate],
  };
</script>

<style scoped>
  div {
    margin-top: 40px;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    color: grey;
    font-weight: 700;
  }

  i {
    font-size: 32px;
    margin-bottom: 2px;
  }
</style>
