<template>
  <div class="survey-chooser">
    <div class="element materialShadow">
      <button class="lsc-button lsc-rounded fullWidth"
              v-for="survey in availableSurveys" :key="survey.id"
              @click="select(survey)"
      >{{ survey.name }}
      </button>
      <button class="lsc-button lsc-rounded fullWidth red" @click="cancel">{{ x('cancel')}}</button>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Survey from '@/components/Survey/Survey';
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from './Mixins/Translate';

  export default {
    name: 'SurveyChooser',
    mixins: [translate],
    emits: [ComponentEventNames.didLoad],
    computed: {
      ...mapState('SurveyStore', ['availableSurveys']),
    },
    mounted() {
      this.$emit(ComponentEventNames.didLoad);
    },
    methods: {
      select(survey) {
        this.$store.commit('SurveyStore/setCurrentSurvey', survey);
        this.$store.commit('pushPopup', {
          component: Survey,
          title: this.x('fillOutSurvey'),
          direction: 'up',
          showSuffix: true,
        });
      },
      cancel() {
        this.$store.commit('popPopup');
      },
    },
  };
</script>

<style scoped lang="scss">
  .survey-chooser {
    height: calc(100vh - 60px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;

    button {
      margin-bottom: 6px;
    }
  }
</style>
