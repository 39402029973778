<template>
  <li id="contentLeft" class="ThemeBackgroundColor" :class="{ open: isMenuOpen }">

    <div v-if="isLoggedIn" id="menuLeft" class="ThemeMenuBackgroundBottom">
      <div class="menu-top ThemeMenuBackgroundTop">
        <a @click="$store.dispatch('closeMenu')" class="closeMenu">
          <icon name="fa-close ThemeFavIcon"/>
        </a>
        <div class="lsc-clear"></div>
        <img src="api/v1/logo.png" class="logo" alt="logo">
      </div>

      <dl>
        <dt>Posmo</dt>
        <template v-if="user.userRole === 'internal'">
          <dd><a @click="showPage(Views.MyPosts)"
                 :class="{ selected: currentPage === Views.MyPosts }" :test="currentPage">
            <icon name="fa-photo"/>{{x('myVisits')}}</a></dd>
          <dd><a @click="showPage(Views.MyDistrict)"
                 :class="{ selected: currentPage === Views.MyDistrict }">
            <icon name="fa-globe"/>{{x('district')}}</a>
          </dd>
          <dd><a @click="showPage(Views.AllPosts)"
                 :class="{ selected: currentPage === Views.AllPosts }">
            <icon name="fa-users"/>{{x('allVisits')}}</a>
          </dd>
          <dd v-if="bookmarksEnabled">
            <a @click="showPage(Views.Bookmarks)"
               :class="{ selected: currentPage === Views.Bookmarks }">
              <icon name="fa-heart"/>{{x('bookmarks')}}</a>
          </dd>
          <dd>
            <a @click="showPage(Views.Customers)">
              <icon name="fa-home"/>{{ x('customers') }}
            </a>
          </dd>
          <dd v-if="visitPlansEnabled">
            <a @click="showPage(Views.VisitPlansApp)">
              <icon name="fa-truck"/>{{ x('visitPlans')}}
            </a>
          </dd>
        </template>

        <template v-if="user.userRole === 'external'">
          <dd v-if="visitPlansEnabled">
            <a @click="showPage(Views.OwnVisitPlanVisits)"
                   :class="{ selected: currentPage === Views.OwnVisitPlanVisits }" :test="currentPage">
              <icon name="fa-photo"/>{{x('myVisits')}}
            </a>
            <a @click="showPage(Views.AllVisitPlanVisits)"
                   :class="{ selected: currentPage === Views.AllVisitPlanVisits }">
              <icon name="fa-users"/>{{x('allVisits')}}
            </a>
            <a @click="showPage(Views.VisitPlansApp)">
              <icon name="fa-truck"/>{{ x('visitPlans')}}
            </a>
          </dd>
        </template>

        <template v-if="user.initialUserRole==='both'">
          <dt class="role">{{x('accessLevel')}}</dt>
          <dd class="role int">
            <a @click="setRole('internal')" :class="{selected: user.userRole==='internal'}">
              <icon :name="{ 'fa-check-circle': isInternal, 'fa-circle-thin': isExternal }"/>
              {{x('internal')}}
            </a>
          </dd>
          <dd class="role ex">
            <a @click="setRole('external')" :class="{selected: user.userRole==='external'}">
              <icon :name="{ 'fa-check-circle': isExternal, 'fa-circle-thin': isInternal }" />
              {{x('external')}}
            </a>
          </dd>
        </template>

        <dt>System</dt>
        <dd><a @click="showPage(Views.MyProfile)"><icon name="fa-pencil"/>{{x('myProfile')}}</a>
        </dd>
        <dd><a @click="showAnnouncements()"><icon
          name="fa-bullhorn"/>{{x('myAnnouncements')}}</a></dd>
        <dd><a @click="showInstallHelp()"><icon name="fa-question"/>{{x('myInstallHelp')}}</a>
        </dd>
        <dd><a @click="logout()"><icon name="fa-sign-out"/>{{x('logOut')}}<span
          v-show="logoutConfirm" class="log-out confirm lsc-rounded-10 ThemeConfirmLogout"><icon
          name="fa-chevron-circle-left"/>{{x('confirm')}}</span></a></dd>

      </dl>

      <img :src="'static/assets/gfx/' + $store.state.config.publicProperties.PosmoLogoMenuPage"
           class="menu_logo">
    </div>
    <div v-if="isMenuOpen" class="CloseCover" @click="$store.dispatch('closeMenu')">${
      this.x('close') }
    </div>
  </li>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState } from 'vuex';
  import { isEmpty } from 'lodash';
  import Logout from '@/classes/logout';
  import Views from '@/enums/views';
  import featureNames from '@/enums/feature-names';
  import translate from './Mixins/Translate';

  export default {
    name: 'app-menu',
    mixins: [translate],
    data() {
      return {
        Views,
        logoutConfirm: false,
        confirmTimer: null,
      };
    },
    computed: {
      isInternal() { return this.user.userRole === 'internal'; },
      isExternal() { return this.user.userRole === 'external'; },
      isLoggedIn() { return !isEmpty(this.user); },
      bookmarksEnabled() { return this.config.features[featureNames.bookmarks]; },
      visitPlansEnabled() { return this.config.features[featureNames.visitPlans]; },
      ...mapState(['config', 'user', 'currentPage', 'isMenuOpen']),
    },
    watch: {
      async currentPage(page) {
        console.log('Current page changed. New page: ', page);
        let newPage = page;
        if (isEmpty(this.user)) {
          newPage = Views.AppLogin;
        }
        this.routeToPage(newPage);
      },
    },
    mounted() {
      this.routeToPage(this.currentPage);
    },
    methods: {
      async showPage(pageName) {
        this.$store.commit('setFilter', null);
        this.$store.commit('setCurrentPage', pageName);
        await this.$store.dispatch('closeMenu');
      },
      async showInstallHelp() {
        const component = defineAsyncComponent(() => import('./UserProfile/InstallHelp'));
        this.$store.commit('pushPopup', {
          component,
          title: this.x('installHelp'),
          direction: 'right',
        });
        await this.$store.dispatch('closeMenu');
      },
      async showAnnouncements() {
        const component = defineAsyncComponent(() => import('./Announcements/AnnouncementList'));
        this.$store.commit('pushPopup', {
          title: this.x('headlineAnnouncements'),
          component,
          direction: 'left',
        });
        await this.$store.dispatch('closeMenu');
      },
      async setRole(role) {
        await window.bootstrap.setRoleUpdateUI(role, 'set');
        await this.$store.dispatch('closeMenu');
      },
      logout() {
        console.log('Logout');
        if (this.logoutConfirm) {
          this.confirmTimer = null;
          Logout();
        } else {
          this.logoutConfirm = true;
          this.confirmTimer = setTimeout(() => {
            this.logoutConfirm = false;
          }, 2000);
        }
      },
      routeToPage(pageName) {
        let route;
        switch (pageName) {
          case Views.MyPosts:
          case Views.MyDistrict:
          case Views.AllPosts:
          case Views.OwnVisitPlanVisits:
          case Views.AllVisitPlanVisits:
          case Views.Bookmarks:
            route = `/feed/${pageName}`;
            break;

          case Views.Customers:
            route = '/customers';
            break;

          case Views.VisitPlansApp:
            route = '/visit-plans-app';
            break;

          case Views.MyProfile:
            route = '/profile';
            break;

          case Views.AppLogin:
            route = '/AppLogin';
            break;

          default:
            return;
        }
        if (this.$router.currentRoute.path === route) return;
        this.$router.push(route);
      },
    },
  };
</script>

<style scoped>
  a {
    cursor: hand;
  }
</style>
