export default {
  methods: {
    /** Returns a boolean indicating if the current user has the specified permission */
    may(permission) {
      if (!this.$store.state.user.permissions) {
        console.warn('Permissions are not loaded. Reload the app to fix.');
        return false;
      }
      return this.$store.state.user.permissions.indexOf(permission) > -1;
    },
  },
};
