<template>
  <li id="contentTop" class="ThemeBackgroundColor">
    <ul class="top">
      <li><a @click="$store.dispatch('openMenu')"><i class="fa fa-bars ThemeFavIcon"></i></a></li>
      <li>{{ x(ui(currentPage).title) }}</li>
      <li>
        <a v-if="showReportButton"
           @click="showReportPage">
          <icon name="fa-book"/>
        </a>
        <a v-if="showViewToggle" @click="toggleView()" class="view_type">
          <icon :name="toggleButtonClass"/>
        </a>
        <a v-if="ui(currentPage).filter" @click="showUserFilter()">
          <icon :name="'fa-filter' + (hasFilter ? ' filterStyle' : '')"/>
        </a>
        <a v-if="ui(currentPage).notifications" @click="loadNotifications()">
          <span class="notification lsc-rounded" :class="{ hasData: unreadNotificationCount > 0 }">
            {{ unreadNotificationCount }}
          </span>
          <icon name="fa-bell ThemeFavIcon"/>
        </a>
      </li>
    </ul>
  </li>
</template>

<script>
  /**
   * The TopBar is the component showing the Top Bar in the App, across all screens.
   */
  import { defineAsyncComponent } from 'vue';
  import { mapState, mapGetters } from 'vuex';
  import featureNames from '@/enums/feature-names';
  import Permissions from '@/enums/permissions';
  import uiStates from '@/components/Mixins/uiStates';
  import translate from './Mixins/Translate';
  import May from './Mixins/May';
  import DisplayModes from '../enums/display-modes';

  const Events = require('../enums/event-names');

  const ListFilter = defineAsyncComponent(() => import('./Filter/ListFilter.vue'));
  const NotificationList = defineAsyncComponent(() => import('./Notifications/NotificationsList'));
  const FeedReport = defineAsyncComponent(() => import('./Pages/FeedReport'));

  export default {
    name: 'top-bar',
    mixins: [translate, May],
    data() {
      // uiStates must match the string values in the views.js enum.
      return {
        featureNames,
        uiStates,
      };
    },
    computed: {
      showViewToggle() {
        if (this.uiStates[this.currentPage] === undefined) {
          return false;
        }
        return this.uiStates[this.currentPage].viewToggle;
      },
      toggleButtonClass() {
        switch (this.displayModes[this.currentPage]) {
          case DisplayModes.LargeCards:
            return 'fa-square-o';
          case DisplayModes.SmallCards:
            return 'fa-th-large';
          case DisplayModes.Insta:
            return 'fa-th';
          default:
            return 'fa-question-square'; // Error
        }
      },
      hasFilter() {
        return this.filter !== undefined && this.filter != null;
      },
      showReportButton() {
        return this.ui(this.currentPage).filter
          && this.config.features[featureNames.reports]
          && this.may(Permissions.orderMultipleReports)
          && this.getReportTypesForFeed.length > 0;
      },
      ...mapState(['config', 'user', 'currentPage', 'displayModes', 'filter', 'unreadNotificationCount']),
      ...mapGetters('ReportStore', ['getReportTypesForFeed']),
    },
    mounted() {
      this.registerEventHandlers();
    },
    beforeUnmount() {
      this.unregisterEventHandlers();
    },
    methods: {
      registerEventHandlers() {
        this.$bus.on(Events.receivedNotification, this.receivedNotification);
        this.$bus.on(Events.resetNotificationCount, this.markAllNotificationsRead);
      },
      unregisterEventHandlers() {
        this.$bus.off(Events.receivedNotification, this.receivedNotification);
        this.$bus.off(Events.resetNotificationCount, this.markAllNotificationsRead);
      },
      /**
       * This method does not directly do anything on the topbar.
       * It receives events from the event bus and increases the notification counter in vuex.
       * That in turn is received back in this component and shown.
       */
      receivedNotification() {
        this.$store.commit('increaseUnreadNotifications');
      },
      markAllNotificationsRead() {
        this.$store.commit('markAllNotificationsRead');
      },
      ui(state) {
        if (state in this.uiStates) {
          return this.uiStates[state];
        }

        if (state !== '' && state !== null) {
          console.warn(`Topbar: No data for state "${state}"`);
        }
        return {
          title: '',
          viewToggle: false,
          filter: false,
          notification: false,
        };
      },
      toggleView() {
        const obj = {};
        obj[this.currentPage] = DisplayModes.nextMode(this.displayModes[this.currentPage]);
        this.$store.commit('setDisplayMode', obj);
      },
      showUserFilter() {
        this.$store.commit('pushPopup', {
          title: 'Filter',
          component: ListFilter,
          direction: 'down',
        });
      },
      loadNotifications() {
        const title = this.x('notifications');
        this.$store.commit('pushPopup', {
          title,
          component: NotificationList,
          direction: 'down',
        });
      },
      showReportPage() {
        const title = this.x('orderReport');
        this.$store.commit('pushPopup', {
          title,
          component: FeedReport,
          direction: 'down',
        });
      },
    },
  };
</script>

<style scoped lang="scss">
#contentTop {
  background-color: var(--ThemeBackgroundColor) !important;
}

.filterStyle {
  color: var(--ThemeButtonGlowing) !important;
  text-shadow: 0 0 14px #ffd700 !important;
}

.notification {
  display: none;
  position: absolute;
  background: #e74c3c;
  color: #ffffff;
  right: 12px;
  top: 5px;
  padding: 3px 5px;
  font-weight: 700;
  font-size: 10px;
  border: 0px solid !important;
  min-width: 19px;
  text-align: center;

  &.hasData {
    display: block;
  }
}

</style>
